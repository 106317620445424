import * as amplitude from "@amplitude/analytics-browser";
import { Button, Input } from "antd";
import axios from "axios";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Spinner from "../../components/Loading/Spinner";
import { getBackendUrl } from "../../utils";
import IntuitBankFeedsCommonLayout, {
  LOCAL_STORAGE_ORG_INFO,
  OrgInfo,
} from "./IntuitBankFeedsCommon";

const IntuitBankFeedsAuth: React.FC = () => {
  const [isFetchingOrgInfo, setIsFetchingOrgInfo] = useState<boolean>(true);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const { search, pathname } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { login_challenge } = parsedSearch;

  const [password, setPassword] = useState<string>("");
  const [orgInfo, setOrgInfo] = useState<OrgInfo>({});
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    axios
      .get(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/org?login_challenge=${login_challenge}`,
      )
      .then((response) => {
        if (typeof response.data !== "object") {
          setOrgInfo({ orgId: response.data });
        } else {
          const {
            orgId,
            companyLogoUrl,
            otpInstructions,
            name,
            redirectUrl,
            termsAndConditions,
          } = response.data;
          const orgInfo: OrgInfo = {
            orgId,
            companyLogoUrl,
            otpInstructions,
            name,
            termsAndConditions,
          };
          setOrgInfo(orgInfo);
          localStorage.setItem(LOCAL_STORAGE_ORG_INFO, JSON.stringify(orgInfo));
          amplitude.setGroup("orgId", orgId);
          amplitude.track("Intuit Bank Feeds: Auth Page Loaded");

          if (redirectUrl) {
            setIsRedirecting(true);
            window.location.replace(redirectUrl);
          }
        }
      })
      .catch((err) => {}) // No action on error - let a later step fail.
      .finally(() => {
        setIsFetchingOrgInfo(false);
      });
  }, [login_challenge, setOrgInfo, setIsFetchingOrgInfo]);

  const handleCompleteSubmit = async () => {
    amplitude.track("Intuit Bank Feeds: Auth Submit Clicked");
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/login?challenge=${login_challenge}&otp=${password}&orgId=${
          orgInfo.orgId
        }`,
      );
      amplitude.track(
        "Intuit Bank Feeds: Auth OTP Success, redirecting to Ory",
      );

      window.location.replace(response.data);
    } catch (e: any) {
      amplitude.track("Intuit Bank Feeds: Auth OTP Error");
      setErrorMessage(
        e.response?.data?.error_message ?? "Something went wrong.",
      );
      setIsSubmitting(false);
    }
  };

  const handleCompleteCancel = () => {
    amplitude.track("Intuit Bank Feeds: Auth Cancel Clicked");
    history.push({
      pathname: "/intuit_bank_feeds_cancelled",
      state: { orgInfo },
    });
  };

  return isRedirecting || isFetchingOrgInfo ? (
    <Spinner />
  ) : (
    <IntuitBankFeedsCommonLayout
      companyLogoUrl={orgInfo.companyLogoUrl}
      name={orgInfo.name}
      errorMessage={errorMessage}>
      <h2 className="text-lg mt-4">One-Time Password (OTP)</h2>
      <div className="mt-1 font-thin" style={{ fontWeight: 300 }}>
        {orgInfo.otpInstructions ??
          "Please enter the one-time password (OTP) provided to you by your financial institution. If you don't see it, check your financial institution's documentation for connecting to Intuit Bank Feeds."}
      </div>
      <div className="mt-2">
        <Input
          placeholder=""
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button
        className="mt-3"
        type="primary"
        block
        disabled={!password}
        loading={isSubmitting}
        onClick={handleCompleteSubmit}>
        Submit
      </Button>
      <Button
        className="mt-3"
        type="default"
        block
        onClick={handleCompleteCancel}>
        Cancel
      </Button>
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsAuth;
