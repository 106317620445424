import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Spinner from "../../components/Loading/Spinner";
import { getBackendUrl } from "../../utils";
import IntuitBankFeedsCommonLayout from "./IntuitBankFeedsCommon";

const IntuitBankFeedsRedirect: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const history = useHistory();
  const { search } = history.location;
  const parsedSearch: any = qs.parse(search);
  const { challenge, otp } = parsedSearch;

  useEffect(() => {
    amplitude.track("Intuit Bank Feeds: Oauth Redirect");

    axios
      .post(
        `${getBackendUrl()}/intuit_bank_feeds_connector/auth/login?rutter_challenge=${challenge}&otp=${otp}`,
      )
      .then((response) => {
        amplitude.track(
          "Intuit Bank Feeds: Oauth OTP Success, redirecting to Ory",
        );

        window.location.replace(response.data);
      })
      .catch((err) => {
        amplitude.track("Intuit Bank Feeds: Oauth OTP Error");
        setErrorMessage(
          err.response?.data?.error_message ?? "Something went wrong.",
        );
        setIsLoading(false);
      });
  }, [setErrorMessage, setIsLoading]);

  return isLoading ? (
    <Spinner />
  ) : (
    <IntuitBankFeedsCommonLayout errorMessage={errorMessage}>
      <span className="text-red-500">
        Please retry the auth process from the beginning.
      </span>
    </IntuitBankFeedsCommonLayout>
  );
};

export default IntuitBankFeedsRedirect;
